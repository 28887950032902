
import HeaderPage from "@/view/content/widgets/engage/HeaderPage.vue";
import { defineComponent, toRef, computed } from "vue";

export default defineComponent({
  name: "Widget3",
  components: { HeaderPage },
  props: {
    image: String,
    title: String,
    subtitle: String,
    description: String,
    price: String,
    attrs: Array,
  },
  setup(props) {
    const image = toRef(props, "image");
    const title = toRef(props, "title");
    const subtitle = toRef(props, "subtitle");
    const description = toRef(props, "description");
    const price = toRef(props, "price");
    const attrs = toRef(props, "attrs");

    const bgImage = computed(() => {
      return image.value ? image.value : "";
    });

    const titleText = computed(() => {
      return title.value ? title.value : "";
    });

    const subtitleText = computed(() => {
      return subtitle.value ? subtitle.value : "";
    });

    const descriptionText = computed(() => {
      return description.value ? description.value : "";
    });

    const priceText = computed(() => {
      return price.value ? price.value : "";
    });

    const attrValues = computed(() => {
      return attrs.value ? attrs.value : "";
    });

    return {
      bgImage,
      titleText,
      subtitleText,
      descriptionText,
      priceText,
      attrValues,
    };
  },
});
