
import { defineComponent } from "vue";
import ProductWidget from "@/view/content/widgets/engage/Widget3.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import productData from "@/data/product-collection.json";

export default defineComponent({
  name: "product",
  components: {
    ProductWidget,
  },
  setup() {
    const product = productData.featureCollection[0];
    setCurrentPageBreadcrumbs("Marketplace", product.name);

    return {
      product,
    };
  },
});
