import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card-custom" }
const _hoisted_2 = { class: "card d-flex py-2 px-2 flex-column flex-md-row flex-lg-column flex-xxl-row" }
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_page = _resolveComponent("header-page")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_header_page, {
      id: "header-page-ai-tool",
      title: _ctx.titleText,
      subtitle: _ctx.subtitleText,
      "bg-image": _ctx.image,
      "bg-image-small": _ctx.image,
      lg: false,
      bgHex: "#fff"
    }, null, 8, ["title", "subtitle", "bg-image", "bg-image-small"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "fs-2 mb-8",
        innerHTML: _ctx.descriptionText
      }, null, 8, _hoisted_3)
    ])
  ]))
}